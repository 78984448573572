<template>
    <carbone-studio ref="studio-nocode" @connected="loaded" @template:updated="templateUpdated" @options:updated="dataUpdated" style="height:100%;padding-top:56px;display:block"></carbone-studio>
</template>

<script>

import config                       from '../config';
import { mapGetters }   from 'vuex';
import bus        from 'src/Components/bus';

export default {
  props : ['apiKeys'],
  data () {
    return {
      apiKeys : null
    };
  },
  computed : {
    ...mapGetters({
      current      : 'current',
      idFileServer : 'idFileServer',
      ext          : 'ext'
    })
  },
  methods : {
    loaded () {
      const studio = this.$refs['studio-nocode'];
      studio.setConfig({
        origin    : config.CARBONE_API_CLOUD_URL,
        token     : this.apiKeys.testToken,
        testToken : this.apiKeys.testToken,
        prodToken : this.apiKeys.prodToken  
      });
      this.loadTemplate();
    },
    loadTemplate () {
      const studio = this.$refs['studio-nocode'];
      studio.openTemplate({ templateId : this.idFileServer, extension : this.ext }, { 
        data         : typeof this.current.version.data === 'string' ? JSON.parse(this.current.version.data) : {},
        complement   : typeof this.current.version.complement === 'string' ?  JSON.parse(this.current.version.complement) : {},
        enum         : typeof this.current.version.enum === 'string' ? JSON.parse(this.current.version.enum) : {},
        translations : typeof this.current.version.translations === 'string' ?JSON.parse(this.current.version.translations) : {},
      });
    },
    templateUpdated (e) {
      if (this.idFileServer !== e.detail.templateId) {
        this.$store.dispatch('updateFilename', { idTemplate : e.detail.templateId, ext : e.detail.extension });
      }
    },
    dataUpdated (e) {
      this.$store.dispatch('updateData', e.detail);
    },
    resetData () {
      const studio = this.$refs['studio-nocode'];
      if (studio !== null && studio !== undefined) {
        studio.reset();
      }
    }
  },
  mounted () {
    bus.$on('resetData', this.resetData);
    bus.$on('nocode:update', this.loadTemplate);
  },
  beforeDestroy () {
    bus.$off('resetData', this.resetData);
    bus.$off('nocode:update', this.loadTemplate);
  }
};
</script>